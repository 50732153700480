import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const MediaTable = ({ mediaList, isLoading }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({
    main_page_main_text: "",
    main_page_para_text: "",
    footer_text: "",
    main_page_image: null,
    logo_image: null,
    favicon_image: null,
    existing_main_image_url: "",
    existing_logo_image_url: "",
    existing_favicon_image_url: "",
  });

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this media?")) {
      try {
        await axios.delete(
          `https://www.gotnextcamps.com/api/admin-add-media/media/${id}`
        );
        toast.success("Media deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete media.");
      }
    }
  };

  const handleUpdateClick = (media) => {
    setSelectedMedia(media._id);
    setUpdateFormData({
      main_page_main_text: media.main_page_main_text,
      main_page_para_text: media.main_page_para_text,
      footer_text: media.footer_text,
      main_page_image: null,
      logo_image: null,
      favicon_image: null,
      existing_main_image_url: media.main_page_image,
      existing_logo_image_url: media.logo_image,
      existing_favicon_image_url: media.favicon_image,
    });
    setIsUpdating(true);
  };

  const handleUpdateChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setUpdateFormData({ ...updateFormData, [name]: files[0] });
    } else {
      setUpdateFormData({ ...updateFormData, [name]: value });
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    if (updateFormData.main_page_main_text) {
      data.append("main_page_main_text", updateFormData.main_page_main_text);
    }
    if (updateFormData.main_page_para_text) {
      data.append("main_page_para_text", updateFormData.main_page_para_text);
    }
    if (updateFormData.footer_text) {
      data.append("footer_text", updateFormData.footer_text);
    }

    // Handle new images
    if (updateFormData.main_page_image) {
      data.append("main_page_image", updateFormData.main_page_image);
    } else {
      data.append(
        "existing_main_page_image_url",
        updateFormData.existing_main_image_url
      );
    }

    if (updateFormData.logo_image) {
      data.append("logo_image", updateFormData.logo_image);
    } else {
      data.append(
        "existing_logo_image_url",
        updateFormData.existing_logo_image_url
      );
    }

    if (updateFormData.favicon_image) {
      data.append("favicon_image", updateFormData.favicon_image);
    } else {
      data.append(
        "existing_favicon_image_url",
        updateFormData.existing_favicon_image_url
      );
    }

    try {
      await axios.patch(
        `https://www.gotnextcamps.com/api/admin-add-media/media/${selectedMedia}`,
        data
      );
      toast.success("Media updated successfully!");
      setIsUpdating(false);
      setSelectedMedia(null);
    } catch (error) {
      toast.error("Failed to update media.");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isUpdating ? (
        <form onSubmit={handleUpdateSubmit}>
          <div className="mb-3">
            <label htmlFor="main_page_main_text" className="form-label">
              Main Page Main Text
            </label>
            <input
              type="text"
              className="form-control"
              id="main_page_main_text"
              name="main_page_main_text"
              value={updateFormData.main_page_main_text}
              onChange={handleUpdateChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="main_page_para_text" className="form-label">
              Main Page Paragraph Text
            </label>
            <textarea
              className="form-control"
              id="main_page_para_text"
              name="main_page_para_text"
              value={updateFormData.main_page_para_text}
              onChange={handleUpdateChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="footer_text" className="form-label">
              Footer Text
            </label>
            <input
              type="text"
              className="form-control"
              id="footer_text"
              name="footer_text"
              value={updateFormData.footer_text}
              onChange={handleUpdateChange}
              required
            />
          </div>

          {/* Image fields for main image, logo, and favicon */}
          <div className="mb-3">
            <label htmlFor="main_page_image" className="form-label">
              Main Page Image
            </label>
            <input
              type="file"
              className="form-control"
              id="main_page_image"
              name="main_page_image"
              onChange={handleUpdateChange}
            />
            {updateFormData.existing_main_image_url && (
              <img
                src={updateFormData.existing_main_image_url}
                alt="Main"
                width="100"
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="logo_image" className="form-label">
              Logo Image
            </label>
            <input
              type="file"
              className="form-control"
              id="logo_image"
              name="logo_image"
              onChange={handleUpdateChange}
            />
            {updateFormData.existing_logo_image_url && (
              <img
                src={updateFormData.existing_logo_image_url}
                alt="Logo"
                width="100"
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="favicon_image" className="form-label">
              Favicon Image
            </label>
            <input
              type="file"
              className="form-control"
              id="favicon_image"
              name="favicon_image"
              onChange={handleUpdateChange}
            />
            {updateFormData.existing_favicon_image_url && (
              <img
                src={updateFormData.existing_favicon_image_url}
                alt="Favicon"
                width="50"
              />
            )}
          </div>

          <button type="submit" className="btn btn-success">
            Update Media
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsUpdating(false)}
          >
            Cancel
          </button>
        </form>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>Main Text</th>
              <th>Paragraph Text</th>
              <th>Footer Text</th>
              <th>Main Image</th>
              <th>Logo Image</th>
              <th>Favicon Image</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {mediaList.map((media) => (
              <tr key={media._id}>
                <td>{media.main_page_main_text}</td>
                <td>{media.main_page_para_text}</td>
                <td>{media.footer_text}</td>
                <td>
                  <img src={media.main_page_image} alt="Main" width="100" />
                </td>
                <td>
                  <img src={media.logo_image} alt="Logo" width="100" />
                </td>
                <td>
                  <img src={media.favicon_image} alt="Favicon" width="50" />
                </td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => handleUpdateClick(media)}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(media._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MediaTable;

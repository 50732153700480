import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import axios from "axios";

const KeysAddition = () => {
  const [keys, setKeys] = useState({
    GOOGLE_CLIENT_ID: "",
    GOOGLE_CLIENT_SECRET: "",
    FACEBOOK_APP_ID: "",
    FACEBOOK_APP_SECRET: "",
    STRIPE_SECRET_KEY: "",
    STRIPE_PUBLIC_ID: "",
    PUBLISHABLE_KEY: "",
    CLOUDINARY_CLOUD_NAME: "",
    CLOUDINARY_API_KEY: "",
    CLOUDINARY_API_SECRET: "",
    GEOLOCATION_ENCODING_KEY: "",
  });
  const [loading, setLoading] = useState(true);
  const [isNew, setIsNew] = useState(false); // Check if it's a new key entry

  // Fetch the existing keys from the backend
  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await axios.get("https://www.gotnextcamps.com/api/keys/keys");
        if (response.data) {
          setKeys(response.data);
        } else {
          setIsNew(true); // No keys found, allow for insertion
        }
      } catch (error) {
        console.error("Error fetching keys:", error);
        setIsNew(true); // If no keys exist, allow the user to insert them
      } finally {
        setLoading(false);
      }
    };
    fetchKeys();
  }, []);

  // Handle form submission for insert/update
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNew) {
        // Insert new keys
        await axios.post("https://www.gotnextcamps.com/api/keys/keys", keys);
        alert("Keys inserted successfully");
      } else {
        // Update existing keys
        await axios.put("https://www.gotnextcamps.com/api/keys/keys", keys);
        alert("Keys updated successfully");
      }
    } catch (error) {
      console.error("Error saving keys:", error);
      alert("Failed to save keys.");
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    setKeys({ ...keys, [e.target.name]: e.target.value });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Keys Addition</h1>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-7">
                  <div className="card shadow mb-4">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Google Client ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="GOOGLE_CLIENT_ID"
                            value={keys.GOOGLE_CLIENT_ID}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Google Client Secret</label>
                          <input
                            type="text"
                            className="form-control"
                            name="GOOGLE_CLIENT_SECRET"
                            value={keys.GOOGLE_CLIENT_SECRET}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Facebook App ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FACEBOOK_APP_ID"
                            value={keys.FACEBOOK_APP_ID}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Facebook App Secret</label>
                          <input
                            type="text"
                            className="form-control"
                            name="FACEBOOK_APP_SECRET"
                            value={keys.FACEBOOK_APP_SECRET}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Stripe Secret Key</label>
                          <input
                            type="text"
                            className="form-control"
                            name="STRIPE_SECRET_KEY"
                            value={keys.STRIPE_SECRET_KEY}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Stripe Public ID</label>
                          <input
                            type="text"
                            className="form-control"
                            name="STRIPE_PUBLIC_ID"
                            value={keys.STRIPE_PUBLIC_ID}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Publishable Key</label>
                          <input
                            type="text"
                            className="form-control"
                            name="PUBLISHABLE_KEY"
                            value={keys.PUBLISHABLE_KEY}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Cloudinary Cloud Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="CLOUDINARY_CLOUD_NAME"
                            value={keys.CLOUDINARY_CLOUD_NAME}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Cloudinary API Key</label>
                          <input
                            type="text"
                            className="form-control"
                            name="CLOUDINARY_API_KEY"
                            value={keys.CLOUDINARY_API_KEY}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Cloudinary API Secret</label>
                          <input
                            type="text"
                            className="form-control"
                            name="CLOUDINARY_API_SECRET"
                            value={keys.CLOUDINARY_API_SECRET}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label>Geo Location Encoding Key</label>
                          <input
                            type="text"
                            className="form-control"
                            name="GEOLOCATION_ENCODING_KEY"
                            value={keys.GEOLOCATION_ENCODING_KEY}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          {isNew ? "Insert Keys" : "Update Keys"}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeysAddition;

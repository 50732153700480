import React from "react";
import KeysAddition from "../../component/AdminPanel/KeysAddition";

const KeysAdditionPage = () => {
  return (
    <div>
      <KeysAddition />
    </div>
  );
};

export default KeysAdditionPage;

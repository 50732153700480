import React from 'react'
import UpdateEvent from '../../component/AdminPanel/UpdateEvent'

const UpdateEventPage = () => {
  return (
    <div>
        <UpdateEvent/>
    </div>
  )
}

export default UpdateEventPage
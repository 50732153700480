import React from "react";
import {
  FaHome,
  FaUser,
  FaList,
  FaComments,
  FaMoneyCheckAlt,
  FaQuestion,
  FaSignOutAlt,
} from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";


const TabsAccount = () => {
  const location = useLocation();

  const tabs = [
    { path: "/account/overview", icon: <FaHome style={{color:"white"}} />, label: "Dashboard" },
    { path: "/account/profile", icon: <FaUser  style={{color:"white"}} />, label: "Profile Details" },
    { path: "/account/subscriptions", icon: <FaList  style={{color:"white"}} />, label: "Subscriptions" },
    // { path: "/account/social-plus", icon: <FaComments />, label: "Social Plus" },
    { path: "/account/payments", icon: <FaMoneyCheckAlt  style={{color:"white"}} />, label: "Payments" },
    { path: "/account/help", icon: <FaQuestion  style={{color:"white"}} />, label: "Help" },
    { path: "/", icon: <FaSignOutAlt  style={{color:"white"}} />, label: "Logout" },
  ];

  return (
    <div>
      <div className="account-tabs" style={{ overflowX: "auto", flexWrap:"wrap" }}>
        {tabs.map((tab) => (
          <Link to={tab.path} key={tab.path} className={`account-tab ${location.pathname === tab.path ? "account-tab-active" : ""}`}>
            {tab.icon}
            <span  style={{color:"white"}}>{tab.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TabsAccount;

import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Spinner, Alert, Button } from "react-bootstrap";
import axios from "axios";
import PaginatedTable from "./PaginatedTable";

const ExcelFile = () => {
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [processedCount, setProcessedCount] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [rowsToSend, setRowsToSend] = useState([]);
  const [sendEnabled, setSendEnabled] = useState(false);

  const [keys, setKeys] = useState({
    GEOLOCATION_ENCODING_KEY: "",
  });

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await axios.get("https://www.gotnextcamps.com/api/keys/keys");
        if (response.data) {
          setKeys(response.data);
        }
      } catch (error) {
        console.error("Error fetching keys:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchKeys();
  }, []);

  const apiUrl = "https://www.gotnextcamps.com/api/event/registration/creation/events/file-import";

  const fetchCoordinates = async (address) => {
    try {
      const response = await fetch(
        `https://api.locationiq.com/v1/autocomplete?key=${keys.GEOLOCATION_ENCODING_KEY}&q=${address}`
      );
      const data = await response.json();
      const coordinates = {
        lat: data[0]?.lat,
        lon: data[0]?.lon,
      };
      return coordinates;
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const createPayload = (row, coordinates) => {
    const title = `${row.Sport || "Athletics"} - ${row.Conference || "N/A"} at ${row.University || "University Name"} in ${row.City || "City Name"}`;
  
    return {
      title: title,
      description: row.Description || "This is a sample description for the athletics event.",
      date: row.Date || new Date().toISOString().split("T")[0],
      time: row.Time || "10:00",
      location: {
        type: "Point",
        coordinates: coordinates ? [coordinates.lon, coordinates.lat] : [0, 0],
        address: `${row.Street || "123 Default St"}, ${row.City || "Default City"}, ${row.State || "CA"}, ${row.Zip || "00000"}`,
      },
      coachProfiles: [],
      reviews: [],
      ageGroups: [],
      category: row.Category || "General",
      recurring: JSON.stringify({ isRecurring: false, endDate: null }),
      isActive: true,
      briefCampIntro: row.BriefCampIntro || "This camp is designed for athletes of all levels.",
      athleticsInfo: {
        division: row.AthleticsDivision || "Senior",
        governingBody: row.GoverningBody || "National Athletics Association"
      },
      socialNetworks: {},
      recruitingQuestionnaire: "",
      tags: [],
      endDate: null,
      campWebsiteLink: row.CampWebsiteLink || "http://example.com",
      images: [],
      videos: [],
    };
  };

  const processBatch = async (rows, startIndex, batchSize) => {
    const updatedRows = [];

    for (let i = startIndex; i < startIndex + batchSize && i < rows.length; i++) {
      const row = rows[i];
      const address = `${row.Street}, ${row.City}, ${row.State}, ${row.Zip}`;
      const coordinates = await fetchCoordinates(address);

      const payload = createPayload(row, coordinates);
      updatedRows.push(payload);

      setProcessedCount(i + 1);
    }

    return updatedRows;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (fileExtension === "csv") {
      Papa.parse(file, {
        header: true,
        complete: async (result) => {
          const parsedData = result.data;
          setTotalRequests(parsedData.length);
          await processRowsInBatches(parsedData);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    } else if (fileExtension === "xlsx" || fileExtension === "xls") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setTotalRequests(jsonData.length);
        await processRowsInBatches(jsonData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      console.error("Unsupported file type. Please upload a CSV or XLSX file.");
    }
  };

  const processRowsInBatches = async (rows) => {
    let allUpdatedRows = [];
    let startIndex = 0;
    const batchSize = 50;

    setStatus("Starting to process rows in batches...");

    while (startIndex < rows.length) {
      setLoading(true);
      setStatus(
        `Processing rows ${startIndex + 1} to ${
          startIndex + batchSize > rows.length ? rows.length : startIndex + batchSize
        }...`
      );

      const updatedRows = await processBatch(rows, startIndex, batchSize);
      allUpdatedRows = [...allUpdatedRows, ...updatedRows];
      setRowsToSend(allUpdatedRows);

      startIndex += batchSize;

      if (startIndex < rows.length) {
        setStatus("Waiting for 1 minute to avoid API rate limit...");
        await new Promise((resolve) => setTimeout(resolve, 60000));
      }
    }

    setSendEnabled(true);
    setStatus("All rows processed.");
    setLoading(false);
    setFileData(allUpdatedRows);
  };

  const checkEventExists = async (title, date) => {
    try {
      const response = await axios.post("https://www.gotnextcamps.com/api/event/registration/creation/events/check-event-exist", {
        title: title,
        date: date,
      });
      return response.data.exists; // Adjust this based on your actual response structure
    } catch (error) {
      console.error("Error checking if event exists:", error);
      return false; // Assume it doesn't exist on error
    }
  };
  
  const sendDataToApi = async () => {
    setLoading(true);
    
    for (let i = 0; i < rowsToSend.length; i++) {
      const row = rowsToSend[i];
      
      const payload = {
        ...row,
        coachProfiles: JSON.stringify(row.coachProfiles || []),
        reviews: JSON.stringify(row.reviews || []),
        ageGroups: JSON.stringify(row.ageGroups || []),
        category: row.category || "General",
        recurring: JSON.stringify(row.recurring || { isRecurring: false }),
        athleticsInfo: JSON.stringify(row.athleticsInfo || {}),
        socialNetworks: JSON.stringify(row.socialNetworks || {}),
        recruitingQuestionnaire: JSON.stringify(row.recruitingQuestionnaire || ""),
        tags: JSON.stringify(row.tags || []),
        location: JSON.stringify(row.location || { type: "Point", coordinates: [0, 0], address: "" }),
        images: JSON.stringify(row.images || []),
        videos: JSON.stringify(row.videos || []),
        isActive: true,
      };
      
      // Check if the event exists before sending data
      const eventExists = await checkEventExists(row.title, row.date);
      
      if (eventExists) {
        // Show modal dialog indicating the event exists
        setShowEventExistsModal(true); // Add state to control the modal
        setStatus(`Event "${row.title}" on ${row.date} already exists.`);
        continue; // Skip sending this payload
      }
  
      try {
        setStatus(`Sending request ${i + 1}/${rowsToSend.length}...`);
        await axios.post(apiUrl, payload);
        setStatus(`Request ${i + 1}/${rowsToSend.length} sent successfully`);
      } catch (error) {
        setStatus(`Error sending request ${i + 1}: ${error.message}`);
      }
  
      setProcessedCount(i + 1);
    }
  
    setLoading(false);
    setStatus("All data processed.");
    setSendEnabled(false);
  };
  
  // Modal dialog for event existence
  const [showEventExistsModal, setShowEventExistsModal] = useState(false);
  
  const handleEventExistsModalClose = () => {
    setShowEventExistsModal(false);
  };
  

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-7">
                  <input
                    type="file"
                    accept=".csv, .xlsx, .xls"
                    onChange={handleFileUpload}
                    className="btn btn-primary mb-3"
                  />

                  {loading && (
                    <div className="d-flex align-items-center">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                      <strong className="ml-2">
                        Processing... {processedCount}/{totalRequests}
                      </strong>
                    </div>
                  )}

                  {status && (
                    <Alert variant="info" className="mt-2">
                      {status}
                    </Alert>
                  )}

                  {fileData && (
                    <>
                      <PaginatedTable data={fileData} rowsPerPage={10} />
                      <Button
                        onClick={sendDataToApi}
                        className="mt-3"
                      >
                        Send Data to API
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    {showEventExistsModal && (
      <div className="modal fade show" style={{ display: "block" }} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Event Exists</h5>
              <button
                type="button"
                className="close"
                onClick={handleEventExistsModalClose}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>This event already exists. Please check your details.</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEventExistsModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default ExcelFile;

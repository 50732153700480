import React, { useContext, useState, useEffect } from "react";
import { EventContext } from "../../../../../context/EventContext";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

const customIconUrl =
  "https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309740_1280.png";

const Step1 = ({ nextStep }) => {
  const { formData, updateFormData } = useContext(EventContext);
  const [location, setLocation] = useState({ coordinates: [], address: "" });
  const [userPosition, setUserPosition] = useState([51.505, -0.09]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [eventExistsModalVisible, setEventExistsModalVisible] = useState(false);
  const [addressInput, setAddressInput] = useState(""); // New state for address input

  const [keys, setKeys] = useState({
    GEOLOCATION_ENCODING_KEY: "",
  });

  // Fetch API keys on component mount
  useEffect(() => {
    const fetchKeys = async () => {
      try {
        const response = await axios.get("https://www.gotnextcamps.com/api/keys/keys");
        if (response.data) {
          setKeys(response.data);
        }
      } catch (error) {
        console.error("Error fetching keys:", error);
      }
    };
    fetchKeys();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserPosition([latitude, longitude]);
        reverseGeocode(latitude, longitude);
      },
      () => {
        console.error("Location access denied.");
        setUserPosition([51.505, -0.09]);
      }
    );
  }, []);

  const reverseGeocode = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
      );
      const address = response.data.display_name;
      setLocation({ coordinates: [lat, lng], address });
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  // Function to fetch coordinates for an address
  const fetchCoordinates = async (address) => {
    try {
      const response = await fetch(
        `https://us1.locationiq.com/v1/search.php?key=${keys.GEOLOCATION_ENCODING_KEY}&q=${encodeURIComponent(address)}&format=json`
      );
      const data = await response.json();
  
      if (data && data[0]) {
        const coordinates = {
          lat: parseFloat(data[0].lat),
          lon: parseFloat(data[0].lon),
        };
        return coordinates;
      } else {
        console.error("No results found for the address.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const handleAddressSearch = async (address) => {
    const coordinates = await fetchCoordinates(address);
    if (coordinates) {
      setMarkerPosition([coordinates.lat, coordinates.lon]);
      setUserPosition([coordinates.lat, coordinates.lon]); // Optionally move the map view
    } else {
      console.error("Unable to fetch valid coordinates for the address.");
    }
  };
  
  

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setSelectedCoordinates([lat, lng]);
    reverseGeocode(lat, lng);
    setModalVisible(true);
  };

  const handleConfirm = () => {
    setMarkerPosition(selectedCoordinates);
    setLocation({
      coordinates: selectedCoordinates,
      address: location.address,
    });
    updateFormData("location", {
      coordinates: selectedCoordinates,
      address: location.address,
    });
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const customIcon = new L.Icon({
    iconUrl: customIconUrl,
    iconSize: [25, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const handleAddressSubmit = async () => {
    const coordinates = await fetchCoordinates(addressInput);
    if (coordinates && coordinates.lat && coordinates.lon) {
      const fetchedAddress = await reverseGeocode(coordinates.lat, coordinates.lon);
  
      setSelectedCoordinates([coordinates.lat, coordinates.lon]);
      setMarkerPosition([coordinates.lat, coordinates.lon]);
      setUserPosition([coordinates.lat, coordinates.lon]);
  
      // Update formData in EventContext with fetched coordinates and address
      updateFormData("location", {
        coordinates: [coordinates.lat, coordinates.lon],
        address: addressInput,
      });
    } else {
      console.error("Unable to fetch valid coordinates for the address.");
    }
  };
  

  
  const LocationMarker = () => {
    const map = useMapEvents({
      click: handleMapClick,
    });
  
    useEffect(() => {
      if (userPosition && userPosition.length === 2) {
        map.setView(new L.LatLng(userPosition[0], userPosition[1]), 13);
      }
    }, [userPosition, map]);
  
    return (
      <>
        {markerPosition && markerPosition.length === 2 && (
          <Marker
            position={new L.LatLng(markerPosition[0], markerPosition[1])}
            icon={customIcon}
          >
            <Popup>{location.address || "Selected Location"}</Popup>
          </Marker>
        )}
        {userPosition && userPosition.length === 2 && (
          <Marker
            position={new L.LatLng(userPosition[0], userPosition[1])}
            icon={customIcon}
          >
            <Popup>Your Current Location</Popup>
          </Marker>
        )}
      </>
    );
  };
  
  

  const checkEventExists = async () => {
    try {
      const response = await axios.get(
        `https://www.gotnextcamps.com/api/event/registration/creation/events/check-event-exist`,
        {
          params: {
            title: formData.title,
            date: formData.date,
          },
        }
      );
      return response.data.exists; // Assuming the response contains a field `exists`
    } catch (error) {
      console.error("Error checking event existence:", error);
      return false; // Assume it doesn't exist if there's an error
    }
  };

  const handleNext = async () => {
    const eventExists = await checkEventExists();
    if (eventExists) {
      setEventExistsModalVisible(true);
    } else {
      nextStep();
    }
  };

  const handleEventExistsModalClose = () => {
    setEventExistsModalVisible(false);
  };

  return (
    <div className="container">
      <h2>Event Basic Details</h2>
      <form>
        <div className="form-group">
          <label>Title</label>
          <span className="text-danger ml-1">*</span>
          <input
            type="text"
            className="form-control"
            value={formData.title}
            onChange={(e) => updateFormData("title", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <span className="text-danger ml-1">*</span>
          <textarea
            className="form-control"
            value={formData.description}
            onChange={(e) => updateFormData("description", e.target.value)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Date</label>
          <span className="text-danger ml-1">*</span>

          <input
            type="date"
            className="form-control"
            value={formData.date}
            onChange={(e) => updateFormData("date", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Start Time</label>
          <span className="text-danger ml-1">*</span>

          <input
            type="time"
            className="form-control"
            value={formData.time}
            onChange={(e) => updateFormData("time", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>End Time</label>
          <span className="text-danger ml-1">*</span>

          <input
            type="time"
            className="form-control"
            value={formData.endtime}
            onChange={(e) => updateFormData("endtime", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>End Date</label> 
          <span className="text-danger ml-1">*</span>
          <input
            type="date"
            className="form-control"
            value={formData.endDate}
            onChange={(e) => updateFormData("endDate", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Cost</label> 
          <input
            type="text"
            className="form-control"
            value={formData.cost}
            onChange={(e) => updateFormData("cost", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Camp Website Link</label> 
          <input
            type="url"
            className="form-control"
            value={formData.campWebsiteLink}
            onChange={(e) => updateFormData("campWebsiteLink", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Location</label>
          <span className="text-danger ml-1">*</span>
          <div style={{ height: "300px", width: "100%" }}>
            <MapContainer
              center={userPosition}
              zoom={13}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <LocationMarker />
            </MapContainer>
          </div>
          <p>Selected Address: {location.address || "123 Dummy Address"}</p>
        </div>
        <div className="form-group">

          <label>Enter Address</label>
          <span className="text-danger ml-1">*</span>
          <input
            type="text"
            className="form-control"
            value={addressInput}
            onChange={(e) => setAddressInput(e.target.value)}
          />
          <button type="button" className="btn btn-primary" onClick={handleAddressSubmit}>
            Search Location
          </button>
        </div>
        <button type="button" className="btn btn-primary" onClick={handleNext}>
          Next
        </button>
      </form>

      {modalVisible && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Location</h5>
                <button type="button" className="close" onClick={handleCancel}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to select this location for the event?
                </p>
                <p>
                  <strong>Address:</strong> {location.address}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {eventExistsModalVisible && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Event Exists</h5>
                <button
                  type="button"
                  className="close"
                  onClick={handleEventExistsModalClose}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>This event already exists. Please check your details.</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleEventExistsModalClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const UpdateEventForm = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    date: "",
    time: "",
    location: { address: "", coordinates: [] },
    coachProfiles: [{ name: "", bio: "", experience: "" }],
    reviews: [{ user: "", review: "", rating: 1 }],
    ageGroups: [{ ageGroup: "", maxParticipants: 0 }],
    category: "",
    recurring: { isRecurring: false, recurrenceRule: "", endDate: "" },
    isActive: true,
    briefCampIntro: "",
    athleticsInfo: {
      governingBody: "",
      division: "",
      conference: "",
      campWebsite: "",
    },
    socialNetworks: { instagram: "", x: "", facebook: "", threads: "" },
    tags: [],
    campWebsiteLink: "",
    endDate: "",
  });

  // Fetch event details for pre-population
  useEffect(() => {
    axios
      .get(`https://www.gotnextcamps.com/api/event/registration/creation/events/${id}`)
      .then((response) => {
        const data = response.data;

        // Format the date, time, and endDate
        setEventData({
          ...data,
          date: data.date
            ? new Date(data.date).toISOString().split("T")[0]
            : "", // Format as YYYY-MM-DD
          time: data.time ? data.time.substring(0, 5) : "", // Extract HH:MM
          endDate: data.endDate
            ? new Date(data.endDate).toISOString().split("T")[0]
            : "", // Format as YYYY-MM-DD
        });
      })
      .catch((error) => console.error(error));
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({ ...eventData, [name]: value });
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      location: { ...eventData.location, [name]: value },
    });
  };

  // Handles input changes for nested fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");

    if (keys.length > 1) {
      setEventData((prevData) => {
        const newData = { ...prevData };
        newData[keys[0]] = { ...newData[keys[0]], [keys[1]]: value };
        return newData;
      });
    } else {
      setEventData({ ...eventData, [name]: value });
    }
  };

  const handleArrayChange = (index, field, value, arrayName) => {
    const updatedArray = [...eventData[arrayName]];
    updatedArray[index][field] = value;
    setEventData({ ...eventData, [arrayName]: updatedArray });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a copy of eventData to avoid modifying the original state directly
    const updatedEventData = { ...eventData };

    // Create the payload with the remaining fields
    const payload = {
        ...eventData,
        location: JSON.stringify({
            type: "Point",
            coordinates: [73.0551, 33.6454], // Use appropriate coordinates
            address: updatedEventData.location.address,
        }),
        coachProfiles: JSON.stringify(updatedEventData.coachProfiles), // Assuming this is an array of objects
        reviews: JSON.stringify(updatedEventData.reviews), // Assuming this is an array of review objects
        ageGroups: JSON.stringify(updatedEventData.ageGroups), // Assuming this is an array of objects
        recurring: JSON.stringify({
            isRecurring: false, // Replace with actual logic if needed
            recurrenceRule: null,
            endDate: updatedEventData.endDate,
        }),
        athleticsInfo: JSON.stringify({
            governingBody: updatedEventData.athleticsInfo.governingBody,
            division: updatedEventData.athleticsInfo.division,
            conference: updatedEventData.athleticsInfo.conference,
            campWebsite: updatedEventData.athleticsInfo.campWebsite,
            recruitingQuestionnaire: updatedEventData.athleticsInfo.recruitingQuestionnaire,
        }),
        socialNetworks: JSON.stringify({
            instagram: updatedEventData.socialNetworks.instagram,
            x: updatedEventData.socialNetworks.x,
            facebook: updatedEventData.socialNetworks.facebook,
            threads: updatedEventData.socialNetworks.threads,
        }),
        tags: JSON.stringify(updatedEventData.tags), // Assuming tags is an array
    };

    console.log(payload);

    // Send the patch request with the updated payload
    axios
        .patch(
            `https://www.gotnextcamps.com/api/event/registration/creation/events/${id}`,
            payload
        )
        .then((response) => {
            alert("Event updated successfully!");
        })
        .catch((error) => console.error("Error updating event:", error));
};

  

  return (
    <div className="container mt-5">
      <form onSubmit={handleSubmit}>
        <h2>Update Event</h2>

        <div className="mb-3">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={eventData.title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Description</label>
          <textarea
            className="form-control"
            name="description"
            value={eventData.description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Date</label>
          <input
            type="date"
            className="form-control"
            name="date"
            value={eventData.date}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Time</label>
          <input
            type="time"
            className="form-control"
            name="time"
            value={eventData.time}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">End Date</label>
          <input
            type="date"
            className="form-control"
            name="endDate"
            value={eventData.endDate}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Location Address</label>
          <input
            type="text"
            className="form-control"
            name="address"
            value={eventData.location.address}
            onChange={handleLocationChange}
          />
        </div>

        <h4>Coach Profiles</h4>
        {eventData.coachProfiles.map((coach, index) => (
          <div key={index}>
            <div className="mb-3">
              <label className="form-label">Coach Name</label>
              <input
                type="text"
                className="form-control"
                value={coach.name}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "name",
                    e.target.value,
                    "coachProfiles"
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Coach Bio</label>
              <textarea
                className="form-control"
                value={coach.bio}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "bio",
                    e.target.value,
                    "coachProfiles"
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Coach Experience</label>
              <input
                type="text"
                className="form-control"
                value={coach.experience}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "experience",
                    e.target.value,
                    "coachProfiles"
                  )
                }
              />
            </div>
          </div>
        ))}

        <h4>Age Groups</h4>
        {eventData.ageGroups.map((group, index) => (
          <div key={index}>
            <div className="mb-3">
              <label className="form-label">Age Group</label>
              <input
                type="text"
                className="form-control"
                value={group.ageGroup}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "ageGroup",
                    e.target.value,
                    "ageGroups"
                  )
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Max Participants</label>
              <input
                type="number"
                className="form-control"
                value={group.maxParticipants}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "maxParticipants",
                    e.target.value,
                    "ageGroups"
                  )
                }
              />
            </div>
          </div>
        ))}

        {/* Social Networks */}
        <div className="mb-3">
          <label htmlFor="instagram" className="form-label">
            Instagram
          </label>
          <input
            type="text"
            className="form-control"
            id="instagram"
            name="instagram"
            value={eventData.socialNetworks.instagram}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="x" className="form-label">
            X (Twitter)
          </label>
          <input
            type="text"
            className="form-control"
            id="x"
            name="x"
            value={eventData.socialNetworks.x}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="facebook" className="form-label">
            Facebook
          </label>
          <input
            type="text"
            className="form-control"
            id="facebook"
            name="facebook"
            value={eventData.socialNetworks.facebook}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="threads" className="form-label">
            Threads
          </label>
          <input
            type="text"
            className="form-control"
            id="threads"
            name="threads"
            value={eventData.socialNetworks.threads}
            onChange={handleInputChange}
          />
        </div>

        {/* Athletics Info */}
        <div className="mb-3">
          <label htmlFor="governingBody" className="form-label">
            Governing Body
          </label>
          <input
            type="text"
            className="form-control"
            id="governingBody"
            name="governingBody"
            value={eventData.athleticsInfo.governingBody}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="division" className="form-label">
            Division
          </label>
          <input
            type="text"
            className="form-control"
            id="division"
            name="division"
            value={eventData.athleticsInfo.division}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="conference" className="form-label">
            Conference
          </label>
          <input
            type="text"
            className="form-control"
            id="conference"
            name="conference"
            value={eventData.athleticsInfo.conference}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="campWebsite" className="form-label">
            Camp Website
          </label>
          <input
            type="text"
            className="form-control"
            id="campWebsite"
            name="campWebsite"
            value={eventData.athleticsInfo.campWebsite}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="recruitingQuestionnaire" className="form-label">
            Recruiting Questionnaire
          </label>
          <textarea
            className="form-control"
            id="recruitingQuestionnaire"
            name="recruitingQuestionnaire"
            rows="3"
            value={eventData.athleticsInfo.recruitingQuestionnaire}
            onChange={handleInputChange}
          ></textarea>
        </div>

        {/* Tags */}
        <div className="mb-3">
          <label htmlFor="tags" className="form-label">
            Tags (Comma Separated)
          </label>
          <input
            type="text"
            className="form-control"
            id="tags"
            name="tags"
            value={eventData.tags}
            onChange={handleInputChange}
          />
        </div>

        {/* Age Groups */}
        <div className="mb-3">
          <label htmlFor="ageGroup" className="form-label">
            Age Group
          </label>
          <input
            type="text"
            className="form-control"
            id="ageGroup"
            name="ageGroup"
            value={eventData.ageGroups.ageGroup}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="maxParticipants" className="form-label">
            Max Participants
          </label>
          <input
            type="number"
            className="form-control"
            id="maxParticipants"
            name="maxParticipants"
            value={eventData.ageGroups.maxParticipants}
            onChange={handleInputChange}
          />
        </div>

        {/* Add more sections for other fields like social networks, reviews, athletics info, etc. */}

        <button type="submit" className="btn btn-primary">
          Update Event
        </button>
      </form>
    </div>
  );
};

export default UpdateEventForm;

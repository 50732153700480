import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import TabsAccount from "../Account/TabsAccount";
import axios from "axios";

const Navbar = () => {
  const location = useLocation();
  const [mediaData, setMediaData] = useState(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  useEffect(() => {
    // Fetch the media data from the API
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(
          "https://www.gotnextcamps.com/api/admin-add-media/media"
        );
        const media = response.data[0]; // Assuming the response is an array
        setMediaData(media);

        // Set favicon dynamically
        if (media.favicon_image) {
          const link = document.createElement("link");
          link.rel = "icon";
          link.href = media.favicon_image;
          document.head.appendChild(link);
        }
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    fetchMediaData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    const isHomePageOrWomenSoccer =
      location.pathname === "/" || location.pathname === "/women-soccer";

    if (isHomePageOrWomenSoccer) {
      window.addEventListener("scroll", handleScroll);
    } else {
      const navbar = document.getElementById("navbar");
      navbar.classList.remove("scrolled");
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const isHomePageOrWomenSoccer =
    location.pathname === "/" || location.pathname === "/women-soccer";

  // Define routes where TabsAccount should be shown
  const accountRoutes = [
    "/account/overview",
    "/account/profile",
    "/account/subscriptions",
    "/account/payments",
    "/account/help",
  ];

  const showTabsAccount = accountRoutes.includes(location.pathname);

  return (
    <nav
      id="navbar"
      className={`navbar navbar-expand-lg ${isHomePageOrWomenSoccer ? "fixed-top" : "navbar-black"}`}
    >
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          {mediaData ? (
            <img
              src={mediaData.logo_image}
              style={{ width: "12rem" }}
              alt="Logo"
            />
          ) : (
            <img
              src="https://gotnextidcamps.com/wp-content/uploads/2024/01/GNID-Logos_Purple-White-Horizonal-1024x364.png"
              style={{ width: "12rem" }}
              alt="Default Logo"
            />
          )}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={!isNavbarCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {showTabsAccount ? (
              <li className="nav-item">
                <TabsAccount />
              </li>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/explore">
                    Explore
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/faqs" className="nav-link">
                    FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/blogs" className="nav-link">
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/subscribe" className="nav-link">
                    Subscribe
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact-us" className="nav-link">
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/signup" className="btn btn-outline-light ms-3" role="button">
                    Sign In/Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
